import React from 'react'
import Image from 'components/Image'
import GatedResource from 'templates/GatedResource'
import coverImage from './images/cover.jpg'
import cover2xImage from './images/cover@2x.jpg'

export default function ResourceReducingFallsWithExercise() {
  const title = 'Reducing falls with exercise'

  return (
    <GatedResource
      title={
        <>
          A Bold fitness guide:
          <br />
          {title}
        </>
      }
      entity="guide"
      subtext="How to achieve health, connection, and independence through exercise. "
      resource={title}>
      <Image src={coverImage} src2x={cover2xImage} alt={`Cover of ‘${{ title }}’`} />
    </GatedResource>
  )
}
